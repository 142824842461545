import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { LayoutMDX } from '@talves/gatsby-theme-site-layout';
import { Link } from 'gatsby';
import { Box } from '../provider';
export const _frontmatter = {
  "title": "Nothing to see here: 404"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutMDX;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Link as="a" to="/" mdxType="Link">Return Home</Link>
    <Box as="h1" sx={{
      mt: [2, 3, 4]
    }} mdxType="Box">
  Page not found!
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      